<template>
  <div class="cards">
    <div class="card">
      <img
        alt="Промокод"
        class="icon"
        src="@/assets/icons/profile/promo-gift.png"
      />
      <form @submit.prevent="promoActivate">
        <div class="form-group">
          <label>Укажите промокод</label>
          <input type="text" required v-model="promo.code" />
        </div>
        <button>Активировать</button>
      </form>
      <div class="bottom">
        <span class="primary">Ваш LVL: {{ user.rank.lvl }}</span>
        <span class="desc">
          Вам доступны промо до {{ convertAmount(promoAmount, "rub") }}₽
        </span>
      </div>
    </div>
    <div class="card">
      <img
        alt="Кешбек"
        class="icon"
        src="@/assets/icons/profile/promo-coin.png"
      />
      <div class="form-group">
        <label>Кешбэк</label>
        <input disabled type="text" value="" />
      </div>
      <button disabled>Скоро</button>
      <div class="bottom">
        <span class="primary">Ваш LVL: {{ user.rank.lvl }}</span>
        <span class="desc">Ваш процент кешбэка: ?%</span>
      </div>
    </div>

    <div v-if="allowCheck('default')" class="card">
      <img
        alt="Промокод"
        class="icon"
        src="@/assets/icons/profile/promo-gift.png"
      />
      <div class="form-group">
        <label>Укажите промокод</label>
        <input type="text" disabled :value="promocodes.default" />
      </div>
      <button @click="createPromocode('default')">
        Создать обычный промокод
      </button>
    </div>

    <div v-if="allowCheck('lvl')" class="card">
      <img
        alt="Промокод"
        class="icon"
        src="@/assets/icons/profile/promo-gift.png"
      />
      <div class="form-group">
        <label>Укажите промокод</label>
        <input type="text" disabled :value="promocodes.lvl" />
      </div>
      <button @click="createPromocode('lvl')">Создать ранговый промокод</button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      promocodes: {},
      promo: {
        code: "",
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["convertAmount"]),
    promoAmount() {
      const rank = this.user.rank;
      return rank.lvl <= 1
        ? rank.lvl * 100
        : 500 * rank.promocode_lvls[rank.lvl];
    },
  },
  async created() {
    try {
      let { data } = await this.$http.get("bonus/promo/init");
      this.promocodes = data.response?.promocodes || [];
    } catch (error) {
      this.$toast.error(error?.message || "Ошибка отправки запроса");
    }
  },
  methods: {
    allowCheck(type) {
      return typeof this.promocodes[type] !== "undefined";
    },
    async promoActivate() {
      try {
        let { data } = await this.$http.post(
          "bonus/promo/activate",
          this.promo
        );

        if (data.error) return this.$toast.error(data.error);
        if (data.response?.message)
          return this.$toast.info(data.response.message);

        this.$store.commit("user/balance", data.response.balances);
        this.$toast.success("Вы успешно активировали промокод");
      } catch (error) {
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
    async createPromocode(type) {
      try {
        let { data } = await this.$http.post("bonus/promo/create", { type });
        if (data.error) return this.$toast.error(data.error);
        this.$toast.info(data.response.message);
        this.promocodes[type] = data.response.promocode;
      } catch (error) {
        this.$toast.error(error?.message || "Ошибка отправки запроса");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .card {
    width: calc(50% - 10px);
    // height: 400px;
    border-radius: 6px;
    margin-bottom: 15px;
    border: 1px solid #e7eef5;
    padding: 30px;
    img.icon {
      margin: 15px auto 35px auto;
      display: block;
    }
    .form-group {
      label {
        // font-weight: 500;
        display: block;
        font-size: 14px;
        color: #9fafc0;
        margin-bottom: 10px;
      }
      input {
        background: #f2f8fd;
        border: 1px solid #d3dfea;
        border-radius: 6px;
        font-weight: 500;
        font-size: 15px;
        display: block;
        width: 100%;
        padding: 20px;
        color: #000000;
      }
      margin-bottom: 15px;
    }
    button {
      background: #0085ff;
      border-radius: 6px;
      display: block;
      padding: 15px;
      font-weight: 500;
      width: 100%;
      font-size: 15px;
      margin-bottom: 15px;
      color: #ffffff;
      &[disabled] {
        pointer-events: none;
        opacity: 0.5;
      }
      transition: 200ms ease;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //   font-weight: 500;
      font-size: 13px;
      color: #9fafc0;
      span.primary {
        text-decoration: underline;
        color: #0085ff;
      }
    }
  }

  @media (max-width: 768px) {
    .card {
      width: 100%;
    }
  }
}
</style>
